$main-black: #000;
$main-yellow: #ffcc00;
$thin-yellow: #fff2bc;
$main-white: #fff;
$main-facebook: #4267b2;
$main-google: #db4a39;
$dark-grey: #555555;
$main-grey: #91969d;
$light-grey: #f1f1f1;
$border-grey: #dddddd;

.pagination {
    margin: 0;
    margin-top: 10px;
    float: right;
  }
  .bg--dark-blue {
    background: #26c3f6;
    color: #333;
  }
  .pagination > .active > a,
  .pagination > .active > a:focus,
  .pagination > .active > a:hover,
  .pagination > .active > span,
  .pagination > .active > span:focus,
  .pagination > .active > span:hover {
    background: #000;
    border-color: #000;
    color: #fff !important;
  }
  
  .pagination button > a,
  .pagination button > span {
    position: relative;

  
    padding: 5px 12px;
    margin-left: 2px;
    line-height: 20px;
    color: #000 !important;
    text-decoration: none;
    background-color: #fffdf1;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    height: 33px;
    min-width: 33px;
    cursor: pointer;
    border: 1px solid #fbe494;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
  }
  
  .pagination > button:first-child > a,
  .pagination > button:first-child > span,
  .pagination > buttonli:last-child > a,
  .pagination > button:last-child > span {
    border-radius: 8px;
  }
  .pagination > button.disabled > span {
    cursor: not-allowed;
    background-color: #e9ecef;
    border-color: #e9ecef;
  }
  .pagination > button {
    background-color: transparent;
    padding: 0;
    box-shadow: none;
    outline: none;
    border: none;
  }
  .pagingWrap .wrapSpan {
    line-height: 1.7;
  }
  .pagingWrap .wrapSpan .form-control {
    min-width: 10px;
    width: 90px !important;
  }
  .pagingWrap .wrapSpan::after {
    content: '|';
    height: 100%;
    padding-left: 1rem;
  }
  .pagingWrap .wrapSpan:last-child::after {
    content: '';
  }
  .white-space-nowrap{
    white-space: nowrap;
  }

@import 'node_modules/bootstrap/scss/bootstrap';
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
@import './App.scss';
@import './styles/components/common/AppHeader.scss';
@import './styles/components/common/AppFooter.scss';
@import './styles/components/common/Login.scss';
@import './styles/components/common/About.scss';
@import './styles/components/common/Rates.scss';
@import './styles/components/common/Features.scss';
@import './styles/components/common/Faq.scss';
@import './styles/components/common/Contact.scss';
@import './styles/components/common/PrivacyAndPolicy.scss';

@import './styles/components/common/Account.scss';
@import './styles/components/common/CommonCard.scss';
@import './styles/components/common/Profile.scss';
@import './styles/components/common/History.scss';
@import './styles/components/common/Table.scss';
@import './styles/components/common/Tabbing.scss';
@import './styles/components/common/Setting.scss';
@import './styles/components/common/AddMinuteBundle.scss';
@import './styles/components/common/Responsive.scss';
@import './styles/components/common/ChangePassword.scss'
