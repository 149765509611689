@media (max-width: 767px) {
  header {
    .navbar {
      .align-items-center {
        align-items: flex-start !important;
        max-height: 100% !important;
      }
      .navbar-toggler {
        border: none;
        &:focus {
          border: none;
          box-shadow: none;
        }
      }
    }
  }
  .flex-column-xs {
    flex-direction: column;
  }
  .d-none-xs {
    display: none !important;
  }
  .font-50 {
    font-size: 32px;
  }
  .aboutPage {
    .banner_about {
      height: 420px;
      .about_right {
        display: none !important;
      }
    }
    .improve_sec {
      padding: 30px 0 !important;
      .big_tabs {
        .about_service_left {
          &:after,
          &:before {
            display: none;
          }
        }
        .about_service_right {
          display: none !important;
        }
      }
    }
    .human_sec {
      flex-wrap: wrap;
      .w-100 {
        width: 50% !important;
        margin: 0 !important;
      }
    }
  }
  .feature_Page_sec {
    .slide_ban {
      height: 200px;
      background-position: center;
    }
    .feature-page {
      .feature-icon {
        grid-template-columns: 45% 45%;
      }
    }
  }
  .rate_page_sec {
    .slide_ban {
      height: 200px;
      background-position: center;
    }
    .w-max-150 {
      max-width: 100%;
    }
    .gap-5 {
      gap: 2rem;
    }
  }
  .faqPage {
    .slide_ban {
      min-height: 200px;
    }
    .faq-page {
      grid-template-columns: 49% 49%;
      grid-gap: 1rem;

      a {
        margin: 0 auto;
      }
      .faq-item {
        width: 140px;
        height: 140px;
        .font-16 {
          font-size: 14px;
        }
        img {
          width: 45px;
        }
      }
    }
    .icons-sec {
      max-width: 100%;
      overflow: auto;
      .icon-item {
        min-width: 100px;
      }
    }
  }
  .contactPage {
    .slide_ban {
      height: 200px;
      background-position: center;
    }
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .flex-column-sm {
    flex-direction: column;
  }
  .d-none-sm {
    display: none !important;
  }
  header {
    .navbar {
      .align-items-center {
        align-items: flex-start !important;
        max-height: 100% !important;
      }
      .navbar-toggler {
        border: none;
        &:focus {
          border: none;
          box-shadow: none;
        }
      }
    }
  }
  .aboutPage {
    .banner_about {
      height: 420px;
      .about_right {
        display: none !important;
      }
    }
    .improve_sec {
      padding: 30px 0 !important;
      .big_tabs {
        .about_service_left {
          &:after,
          &:before {
            display: none;
          }
        }
        .about_service_right {
          display: none !important;
        }
      }
    }
  }
  .feature_Page_sec {
    .slide_ban {
      background-position: center;
    }
  }
  .faqPage {
    .faq-page {
      grid-template-columns: 33.33% 33.33% 33.33%;
      grid-gap: 1rem;
    }
  }
}
