.nav-tabs {
  border-color: $main-black;
  .nav-item {
    .nav-link {
      border: 1px solid $main-black;
      margin-right: 0.6rem;
      color: $main-black;
      border-radius: 3px 3px 0 0;
      padding: 10px;
      text-transform: uppercase;
      &:hover,
      &:focus,
      &.active {
        background: $main-black;
        color: $main-white;
        border-color: $main-black;
      }
    }
  }
}
// .tab-content {
//   .tab-pane {
//     min-height: 10rem;
//     .loader {
//       min-height: 20rem;
//     }
//   }
// }
