.table {
  thead {
    th {
      border-top: 1px solid #ddd;
      border-bottom: 1px solid #ddd;
      text-transform: uppercase;
      font-weight: 300;
      font-size: 13px;
    }
  }
  tbody {
    td {
      padding: 12px 8px;
      font-size: 13px;
    }
  }
}
