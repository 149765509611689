.commanCard {
  padding: 50px 0;
  &_cir {
    width: 4rem;
    height: 4rem;
  }
  .Card_form {
    .form-label {
      max-width: 250px;
    }
    .form-control {
      max-width: 60%;
    }
    .form-text {
      max-width: 450px;
    }
  }
}
