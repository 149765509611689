footer {
  padding: 30px 0;
  .hstack {
    .img-logo {
      max-width: 80px;
    }
    .btn-md {
      width: 150px;
      font-size: 14px;
      padding: 10px 25px;
      &:hover {
        text-decoration: underline;
      }
    }
    .footer-title {
      font-size: 13px;
      font-weight: 400;
    }
    .nav {
      a {
        &:hover,
        &:focus {
          color: $main-yellow !important;
        }
      }
    }
  }
}
