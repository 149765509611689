.aboutPage {
  .banner_about {
    background: url('../../../images/banner_about.jpg') no-repeat center;
    background-size: cover;
    height: 700px;
    position: relative;
    .container {
      z-index: 3;
      position: relative;
    }
    .bg_overlay {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: $main-black;
      opacity: 0.7;
      z-index: 2;
      color: $main-white;
    }

    .about_left {
      img {
        max-width: 200px;
      }
    }
    .about_right {
      position: relative;
      img {
        max-width: 250px;
        position: absolute;
        bottom: 0;
      }
    }
  }
  .improve_sec {
    padding: 50px 0;
    .improve_text {
      h5 {
        max-width: 800px;
        color: $dark-grey;
        line-height: 25px;
      }
    }
    // .about_service {
    //   padding: 40px 0;
    //   .about_service_left {
    //     img {
    //       max-width: 80px;
    //       width: 100%;
    //       min-width: 80px;
    //       max-height: 80px;
    //     }
    //   }
    //   .about_service_right {
    //     img {
    //       max-width: 400px;
    //       width: 100%;
    //     }
    //   }
    // }
    .big_tabs {
      padding: 40px 0;
      .nav-pills {
        border: none;
        .nav-link {
          border: none;
          height: auto;
          background: transparent;
          color: $main-black;
          position: relative;
          &.active,
          &:hover,
          &:focus {
            border: none;
            height: auto;
            background: transparent;
            color: $main-black;
          }
          &.active {
            h3 {
              color: $main-yellow;
            }
            .about_service_left:after {
              content: '';
              width: 130px;
              height: 1px;
              background: $main-yellow;
              position: absolute;
              right: -50px;
              top: 20px;
            }
            .about_service_left:before {
              content: '';
              width: 10px;
              height: 10px;
              border-radius: 100%;
              border: 1px solid $main-yellow;
              position: absolute;
              right: -59px;
              top: 15px;
              background: $main-white;
            }
          }
        }
        .about_service_left {
          img {
            max-width: 80px;
            width: 100%;
            min-width: 80px;
            max-height: 80px;
          }
          &:hover {
            h3 {
              color: $main-yellow;
            }
          }
        }
        .about_service_right {
          img {
            max-width: 400px;
            width: 100%;
          }
        }
      }
      .tab-content {
        width: 100%;
      }
    }
  }
  .Board_sec {
    padding: 40px 0;
    .tab_img {
      img {
        width: 100%;
        max-width: 600px;
      }
    }
    .human_sec {
      margin: 0 auto;
      img {
        height: 300px;
      }
    }
  }
}
