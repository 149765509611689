.p-relative{position: relative;}
.view{
position: absolute;
right: 14px;
cursor: pointer;

}
.view svg{
width: 17px;
height: 17px;
}