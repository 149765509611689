header {
  &.fixed-top {
    z-index: 19;
  }
  .img-logo {
    max-width: 60px;
  }
  .userIcon {
    background: $main-black;
    color: #fff;
    border-radius: 100%;
    width: 2rem;
    height: 2rem;
    svg {
      color: $main-white;
      width: 1.2rem;
    }
  }
  .navbar-nav {
    .nav-link {
      color: $main-black;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 14px;
      &:hover,
      &:focus,
      &.active {
        color: $main-yellow !important;
        text-decoration: underline;
      }
    }
  }
  .dropdown {
    .dropdown-menu-light {
      border: none;
    }
  }
  .flex_img {
    max-width: 20px;
  }
}
