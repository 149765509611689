.callPage {
  padding: 10px 0 30px;

  .form-check {
    display: flex;
    align-items: center;
  }
  .form-check-label {
    font-size: 16px;
    font-weight: 600;
    margin-left: 5px;
  }
}
