body {
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  background: $main-white;
}
.mid-content {
  min-height: 73vh;
  margin-top: 57px;
}
hr {
  background-color: $main-grey;
}
input,
select {
  &:focus {
    outline: none !important;
    box-shadow: none !important;
    border-color: $main-yellow !important;
  }
}
.gap-1 {
  gap: 1rem;
}
.gap-2 {
  gap: 2rem;
}

.gap-3 {
  gap: 3rem;
}
.gap-4 {
  gap: 4rem;
}
.gap-5 {
  gap: 5rem;
}
.pointer {
  cursor: pointer;
}
.svg-yellow {
  svg {
    color: $main-yellow;
    fill: $main-yellow;
  }
}
.svg-white {
  svg {
    color: $main-white;
    fill: $main-white;
  }
}
.radius-100 {
  border-radius: 100%;
}
.bg-facebook {
  background: $main-facebook;
  color: $main-white;
  border-color: $main-facebook !important;
  svg {
    fill: $main-white;
    width: 1.2rem;
  }
}
.btn-facebook {
  background: $main-facebook;
  color: $main-white;
  border-color: $main-facebook !important;
  svg {
    fill: $main-white;
    width: 1.2rem;
  }
  &:hover,
  &:focus {
    background: $main-facebook;
    opacity: 0.9;
    color: $main-white;
  }
}
.bg-google {
  background: $main-google;
  color: $main-white;
  border-color: $main-google !important;
  svg {
    fill: $main-white;
    width: 1.2rem;
  }
}
.btn-google {
  background: $main-google;
  color: $main-white;
  border-color: $main-google !important;
  svg {
    fill: $main-white;
    width: 1.2rem;
  }
  &:hover,
  &:focus {
    background: $main-google;
    opacity: 0.9;
    color: $main-white;
  }
}
.text-yellow {
  color: $main-yellow;
}
.hover-yellow {
  &:hover {
    color: $main-yellow;
  }
}
.hover-black {
  &:hover {
    color: $main-black;
  }
}
.hover-black-bg {
  &:hover {
    background: $main-black;
    color: $main-white;
  }
}
.hover-grey-bg {
  &:hover {
    background: $light-grey;
  }
}
.text-grey {
  color: $main-grey;
}
.latter-spacing {
  letter-spacing: 1px;
}
.uppercase {
  text-transform: uppercase;
}
.font-11 {
  font-size: 11px;
}
.font-12 {
  font-size: 12px;
}
.font-13 {
  font-size: 13px;
}
.font-15 {
  font-size: 15px;
}
.font-16 {
  font-size: 16px;
}
.font-18 {
  font-size: 18px;
}
.font-20 {
  font-size: 20px;
}
.font-24 {
  font-size: 24px;
}
.font-26 {
  font-size: 26px;
}
.font-36 {
  font-size: 36px;
}
.font-50 {
  font-size: 50px;
}
.font-300 {
  font-weight: 300;
}
.font-400 {
  font-weight: 400;
}
.font-500 {
  font-weight: 500;
}
.font-600 {
  font-weight: 600;
}
.font-700 {
  font-weight: 700;
}
.m-w-120 {
  min-width: 120px;
}
.m-w-150 {
  min-width: 150px;
}
.m-w-200 {
  min-width: 200px;
}
.m-w-250 {
  min-width: 250px;
  max-width: 263px;
}
.w-max-150 {
  max-width: 150px;
}
.w-max-500 {
  max-width: 500px;
}
.btn-small {
  height: 32px;
}
.btn {
  border-radius: 4px;
  &:focus {
    box-shadow: none !important;
  }
}
.border-yellow {
  border: 1px solid $main-yellow;
}
.bg-yellow {
  background: $main-yellow;
}
.btn-outline-light {
  border-color: $main-grey;
  color: $main-grey;
}
.btn-yellow,
.btn-warning {
  background: $main-yellow;
  border-color: $main-yellow;
  color: $main-black;
}
.btn-black {
  background: $main-black;
  border-color: $main-yellow;
  color: $main-white;
}

.no-border {
  border: none;
}
.form-control {
  font-size: 13px;
  min-height: 40px;
}
// gagan
.PhoneInputInput {
  border: none;
}
.PhoneInput.is-invalid {
  border-color: #dc3545 !important;
  padding-right: calc(1.5em + 0.75rem) !important;
}

.table tbody td {
  vertical-align: middle;
}

.linearLoader {
  height: 4px;
  width: 100%;
  position: relative;
  overflow: hidden;
}
.linearLoader:before {
  display: block;
  position: absolute;
  content: '';
  left: -200px;
  width: 200px;
  height: 4px;
  background-color: $main-yellow;
  animation: loading 2s linear infinite;
}
.swal-button--confirm {
  border-radius: 4px;
  &:focus {
    box-shadow: none !important;
  }
  background: $main-yellow;
  border-color: $main-yellow;
  color: $main-black;
  :hover {
    background: $main-yellow;
  }
}
@keyframes loading {
  from {
    left: -200px;
    width: 30%;
  }
  50% {
    width: 30%;
  }
  70% {
    width: 70%;
  }
  80% {
    left: 50%;
  }
  95% {
    left: 120%;
  }
  to {
    left: 100%;
  }
}
