.addMinuteBundle {
  padding: 10px 0 50px;
  .grid_system {
    display: grid;
    grid-gap: 2rem;
    grid-template-columns: 23% 23% 23% 23%;
    .grid_item {
      border: 1px solid $border-grey;
      padding: 20px;
      min-height: 16rem;
      overflow: hidden;
      border-radius: 3px;

      .overlay {
        opacity: 0;
        position: absolute;
        left: -200px;
        top: 0;
        width: 100%;
        height: 100%;
        transition: all 0.3s ease-in-out;
        background: $main-black;
        .strip {
          border-radius: 3px;
          background: $main-white;
          &:hover,
          &:focus {
            background: $main-yellow;
          }
        }
      }
      &:hover {
        .overlay {
          opacity: 1;
          display: flex;
          transition: all 0.3s ease-in-out;

          left: 0;
          width: 100%;
        }
      }

      img {
        max-width: 68px;
      }
    }
  }
}
