.Page_sec {
  .slide_ban {
    background: url('../../../images/faq.jpg') no-repeat 50% -100px;
    background-attachment: fixed;
    min-height: 350px;
    padding: 40px 0;
  }

  .faq-page {
    display: grid;
    grid-template-columns: 22% 22% 22% 22%;
    grid-gap: 2rem;
    padding: 60px 0;
    max-width: 1000px;
    margin: 0 auto;
    a {
      text-decoration: none;
      color: $main-black;
      &:hover,
      &:focus {
        text-decoration: none;
        color: $main-yellow;
      }
    }
    .faq-item {
      width: 220px;
      height: 220px;
      text-align: center;
      border-radius: 100%;
      background: $main-white;
      transition: all 0.4s ease-in-out;

      &:hover {
        p {
          color: $main-yellow;
          transition: all 0.4s ease-in-out;
        }
      }

      img {
        max-width: 60px;
      }
    }
  }
  .icons-sec {
    img {
      max-width: 60px;
    }
    a {
      text-decoration: none;
      color: $main-black;
      &:hover,
      &:focus {
        text-decoration: none;
        color: $main-yellow;
      }
    }
    .icon-item {
      margin-right: 5px;
      &:hover,
      &.active {
        p {
          color: $main-yellow;
          transition: all 0.4s ease-in-out;
        }
      }
    }
  }
  .accordion {
    &-item {
      border: none;
      border-bottom: 1px solid $border-grey !important;
    }
    &-header {
      border: none;
    }
    &-body {
      padding: 0 0px 30px;
      ul {
        li {
          font-size: 16px;
          line-height: 27px;
        }
      }
    }
    &-button {
      height: auto;
      background: transparent;
      padding: 30px 0;

      box-shadow: none;
      font-size: 18px;
      line-height: 22px;
      &:focus,
      &:not(.collapsed) {
        box-shadow: none;
        border: none;
        color: $main-yellow !important;
      }
    }
  }
}
