.feature_Page_sec {
  // .slide_ban {
  //   background: url('./src/images/features.jpg') no-repeat center;
  //   background-position: 50% -113px;
  // }
  .slide_ban {
    height: 350px;
    background: url('../../../images/features.jpg') no-repeat center;
    background-position: 50% -113px;
    position: relative;
    background-size: cover;
    z-index: 1;
    .container {
      z-index: 3;
    }
    .bg_overlay {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: $main-black;

      opacity: 0.7;
      z-index: 2;
      color: $main-white;
    }
  }

  .feature-page {
    padding: 50px 0 80px;
    max-width: 900px;
    width: 100%;
    h5 {
      line-height: 28px;
    }
    .feature-icon {
      display: grid;
      grid-template-columns: 22.3% 22.3% 22.3% 22.3%;
      grid-gap: 2rem;
      .feature-item {
        min-height: 9rem;
      }
      img {
        width: 80px;
        height: 80px;
      }
    }
  }
}
