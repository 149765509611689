.account {
  padding: 50px 0;
  &__box {
    h2 {
      font-size: 34px;
      line-height: 40px;
    }
  }
  &__grid {
    display: grid;
    grid-template-columns: auto auto auto auto;
    grid-gap: 1.5rem 3rem;
  }
  &__gridItem {
    position: relative;
    // height: 100%;
    // width: 100%;
  }
  &__overlay {
    // width: 100%;
    // height: 100%;
    position: absolute;
    top: 4px;
    left: 4px;
    border: 1px solid $main-yellow;
    z-index: 1;
  }
  &__digit {
    border: 1px solid $main-black;
    background: $main-white;
    color: $main-black;
    // height: 100%;
    // width: 100%;
    padding: 10px;
    font-size: 20px;
    // height: 50px;
    border-radius: 2px;
    z-index: 3;
    &:hover,
    &:focus {
      background: $main-yellow;
      border: 1px solid $main-yellow;
      color: $main-white;
    }
  }
  .bunndle_icons {
    img {
      max-width: 68px;
      height: 68px;
      width: 100%;
    }
  }
}
