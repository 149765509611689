.contactPage {
  .slide_ban {
    height: 350px;
    background: url('../../../images/contact.jpg') no-repeat center;
    background-position: 50% -113px;
    position: relative;
    background-size: cover;
    z-index: 1;
    .container {
      z-index: 3;
    }
    .bg_overlay {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: $main-black;

      opacity: 0.7;
      z-index: 2;
      color: $main-white;
    }
  }
  .contact-page {
    padding: 50px 0 80px;
    max-width: 900px;
    width: 100%;
    h5 {
      line-height: 28px;
    }
  }
}
