.rate_page_sec {
  .slide_ban {
    height: 350px;
    background: url('../../../images/about_ban.jpg') no-repeat center;
    position: relative;
    background-size: cover;
    z-index: 1;
    .container {
      z-index: 3;
    }
    .bg_overlay {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: $main-black;

      opacity: 0.7;
      z-index: 2;
      color: $main-white;
    }
  }
  .ratePage {
    max-width: 500px;
    .rate-icon {
      img {
        width: 68px;
      }
    }
    .form-select {
      height: 50px;
    }
  }
}
