.login {
  .login_box {
    padding: 30px 0;
    max-width: 320px;
    min-height: calc(100vh - 200px);
    position: relative;
    margin: 0 auto;
    .login_title {
      font-size: 21px;
      font-weight: 600;
    }
    // &:after {
    //   content: "";
    //   width: 100px;
    //   height: 100px;
    //   border: 4px solid $main-yellow;
    //   position: absolute;
    //   right: -59%;
    //   bottom: 10%;
    //   background: $main-white;
    //   border-radius: 100%;
    // }
    // &:before {
    //   content: "";
    //   width: 100px;
    //   height: 100px;
    //   border: 2px solid $main-yellow;
    //   position: absolute;
    //   left: -59%;
    //   top: 10%;
    //   background: $thin-yellow;
    //   border-radius: 100%;
    // }
  }
  .social {
    .social_cir {
      width: 2.5rem;
      height: 2.5rem;
    }
  }
}
