.privacy {
  padding: 50px 0;

  p {
    margin-bottom: 1rem;
    line-height: 22px;
    color: $dark-grey;
  }
  // .list_number {
  //   list-style: decimal;
  // }
  ul {
    margin-top: 1rem;
    list-style: square;
    li {
      margin-bottom: 0.8rem;
      line-height: 22px;
      color: $dark-grey;
      font-size: 15px;
      ul {
        li {
          margin-bottom: 0.8rem;
        }
      }
    }
  }
}
